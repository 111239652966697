import PadraoModel from 'src/core/model/PadraoModel.js'

export default class MobiautoCombustivelModel extends PadraoModel {
  recurso = 'mobiauto/combustiveis';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Combustíveis Mobiauto';

  colunasGrid = [
  ];

  form = {
    id: null,
    name: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
