import PadraoModel from 'src/core/model/PadraoModel.js'

export default class WebmotorsCombustivelModel extends PadraoModel {
  recurso = 'webmotors/combustiveis';
  microServico = 'automotor';
  include = null;
  searchFixo = null;
  nome = 'Combustíveis Webmotors';

  colunasGrid = [
  ];

  form = {
    CodigoCombustivel: null,
    Descricao: null
  };

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
