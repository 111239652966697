import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import CombustivelModel from 'src/model/veiculo/CombustivelModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import IcarrosCombustivelModel from 'src/model/veiculo/IcarrosCombustivelModel'
import WebmotorsCombustivelModel from 'src/model/veiculo/WebmotorsCombustivelModel'
import UsadosbrCombustivelModel from 'src/model/veiculo/UsadosbrCombustivelModel'
import OlxCombustivelModel from 'src/model/veiculo/OlxCombustivelModel'
import MobiautoCombustivelModel from 'src/model/veiculo/MobiautoCombustivelModel'

export default {
  name: 'CombustivelForm',
  components: { DpForm },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      codigos_icarros: [],
      codigos_webmotors: [],
      codigos_usadosbr: [],
      codigos_olx: [],
      codigos_mobiauto: [],
      modelPadrao: new CombustivelModel(),
      modelIcarrosCombustivel: new IcarrosCombustivelModel(),
      modelWebmotorsCombustivel: new WebmotorsCombustivelModel(),
      modelUsadosbrCombustivel: new UsadosbrCombustivelModel(),
      modelOlxCombustivel: new OlxCombustivelModel(),
      modelMobiautoCombustivel: new MobiautoCombustivelModel()
    }
  },
  mounted () {
    this.modelIcarrosCombustivel.getListagem().then((response) => {
      this.codigos_icarros = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelWebmotorsCombustivel.getListagem().then((response) => {
      this.codigos_webmotors = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelUsadosbrCombustivel.getListagem().then((response) => {
      this.codigos_usadosbr = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelOlxCombustivel.getListagem().then((response) => {
      this.codigos_olx = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
    this.modelMobiautoCombustivel.getListagem().then((response) => {
      this.codigos_mobiauto = response.dados ?? []
    }).catch((error) => {
      this.notificacao('erro', error.msg)
    })
  },
  methods: {

  }
}
